import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	bold: {
		fontWeight: 600,
	},
	topLabel: {
		fontSize: 13,
		fontWeight: 600,
		marginBottom: '12px',
	},
}));
