export const BUTTONS = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.fields.most_rented',
		status: '6&order[0][dir]=desc',
	},
];
