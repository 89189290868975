import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 28,
		marginBottom: theme.spacing(4),
		fontWeight: 500,
	},
	container: {
		padding: theme.spacing(4),
		minHeight: 'calc(100vh - 170px)',
	},
	tab: {
		minHeight: 'auto',
	},
}));
