export const convertApiFormErrors = (errors) =>
	Object.keys(errors).reduce((acc, next) => {
		if (next.includes('.')) {
			const parts = next.split('.');
			let current = acc;

			parts.forEach((part, index) => {
				if (index === parts.length - 1) {
					current[part] = errors[next][0];
				} else {
					current[part] = current[part] || {};
					current = current[part];
				}
			});
		} else {
			acc[next] = errors[next][0];
		}
		return acc;
	}, {});

export const removeEmptyValues = (data) => {
	Object.entries(data).forEach(([key, value]) => {
		if (value && typeof value === 'object') {
			removeEmptyValues(value);
		}
		if (
			(value && typeof value === 'object' && !Object.keys(value).length) ||
			null == value ||
			value === ''
		) {
			if (Array.isArray(data)) {
				data.splice(key, 1);
			} else {
				delete data[key];
			}
		}
	});
	return data;
};
