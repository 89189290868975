import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import components
import { FormRPC } from 'components/utilities';
import { FormContent } from './components';

// Import store
import { fetchOfficeFormResources, submitAddOfficesForm } from 'store/actions';
import {
	companyManagerContextSelector,
	officeFormSelector,
} from 'store/selectors';

// Import helpers and utils
import { replaceCompanyOfficesUrl, yupValidator } from 'helpers';
import { URLS } from 'components/routes';
import { INITIAL_VALUES } from './helpers';
import { validationSchema } from './validation';

export const OfficesForm = () => {
	const { companyUuid } = useSelector(companyManagerContextSelector);

	const goBackPath = companyUuid
		? replaceCompanyOfficesUrl(companyUuid)
		: URLS.officesUrl;

	return (
		<Box>
			<FormRPC
				fetchFormResAction={fetchOfficeFormResources}
				submitFormAction={submitAddOfficesForm}
				isCardLayout={false}
				goBackPath={goBackPath}
				store={officeFormSelector}
				initialValues={INITIAL_VALUES}
				isActionButtonsHidden
				noValidate
				validation={(values) => yupValidator({ values, validationSchema })}
				successMessage="common.messages.successfully_created"
			>
				{() => <FormContent />}
			</FormRPC>
		</Box>
	);
};
