import React from 'react';
import { bool, string } from 'prop-types';
import { Field } from 'react-final-form';
import {
	CircularProgress,
	Typography,
	MenuItem,
	Divider,
	Box,
} from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';
import { usePhonePrefixes } from 'queries';

// Import styles
import { useStyles } from './PhoneNumberField.styles';

// Import components
import { TextFieldAdapter } from '../TextFieldAdapter/TextFieldAdapter';
import SelectFieldAdapter from '../SelectFiledAdapter';

export const PhoneNumberFieldAdapter = ({
	phoneNumberName,
	prefixName,
	required = false,
	label,
	defaultValue = '+966',
	...rest
}) => {
	const { t } = useTranslations();

	const { data: prefixes, isFetching } = usePhonePrefixes();

	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Typography className={classes.label}>
				{required ? `${t(label)} *` : t(label)}
			</Typography>
			<Box className={classes.fieldBox}>
				{isFetching ? (
					<Box>
						<CircularProgress size={24} />
					</Box>
				) : (
					<Field
						label=""
						component={SelectFieldAdapter}
						variant="outlined"
						type="select"
						name={prefixName}
						fullWidth
						defaultValue={defaultValue}
						className={classes.prefixField}
					>
						{Object.values(prefixes).map((prefix) => (
							<MenuItem key={prefix} value={prefix}>
								{prefix}
							</MenuItem>
						))}
					</Field>
				)}
				<Divider orientation="vertical" className={classes.divider} />
				<Field
					fullWidth
					type="number"
					label=""
					name={phoneNumberName}
					variant="outlined"
					component={TextFieldAdapter}
					className={classes.phoneNumberField}
					{...rest}
				/>
			</Box>
		</Box>
	);
};

PhoneNumberFieldAdapter.propTypes = {
	phoneNumberName: string.isRequired,
	prefixName: string.isRequired,
	label: string.isRequired,
	required: bool,
	defaultValue: string,
};
