import { useFormState } from 'react-final-form';

// Import utils and helpers
import { getMultiSelectField } from './helpers';
import { useRentalCompaniesProvider } from '../../context';
import { useIsRTL } from 'components/utilities';

export const useFormContent = () => {
	const { companiesList } = useRentalCompaniesProvider();
	const { values } = useFormState();
	const isRTL = useIsRTL();
	const convertedCompaniesList = companiesList.map(({ uuid, name }) => ({
		uuid,
		name: isRTL ? name.ar : name.en,
	}));

	const multiSelectField = getMultiSelectField({
		companiesList: convertedCompaniesList,
		values,
	});

	return { multiSelectField };
};
