import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.white,
		padding: theme.spacing(3),
		position: 'absolute',
		bottom: '-90px',
		left: 0,
		borderTop: `1px solid ${theme.palette.background[5]}`,
	},
}));
