import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import {
	BusinessHoursFieldAdapter,
	TextFieldAdapter,
} from 'components/elements';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

export const DeliveryHours = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<Grid container spacing={3}>
			<Typography className={classes.titleSmall}>
				{t('offices.form.delivery_hours.title')}
			</Typography>
			<Grid item md={12} xs={12}>
				<Field
					name="delivery_hours"
					component={BusinessHoursFieldAdapter}
					disabled={submitting}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="number"
					label={t('offices.form.delivery_hours.min_delivery_time')}
					name="options.min_delivery_time"
					margin="dense"
					variant="outlined"
					disabled={submitting}
					required
					inputProps={{ min: 0 }}
				/>
			</Grid>
		</Grid>
	);
};
