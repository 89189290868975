import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	mapContainer: {
		marginTop: theme.spacing(3),
		borderRadius: '12px',
	},
	addressField: {
		marginBottom: theme.spacing(3),
	},
}));
