import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Field } from 'react-final-form';
import { number } from 'prop-types';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import queries
import { useCorporationCustomersList } from 'queries';

export const CorporateCustomersFilter = ({ md = 3 }) => {
	const { t } = useTranslations();

	const { id: corporateUuid } = useParams();

	const { data: customersList } = useCorporationCustomersList({
		corporateUuid,
	});

	return (
		<Grid item md={md}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t('b2b.corporate_customer')}
				name="user_uuid"
				variant="outlined"
				margin="dense"
			>
				<MenuItem value="all">{t('common.all')}</MenuItem>
				{customersList.map(({ uuid, full_name: name }) => (
					<MenuItem key={uuid} value={uuid}>
						{name}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

CorporateCustomersFilter.propTypes = {
	md: number,
};
