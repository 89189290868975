import React from 'react';
import { object } from 'prop-types';

// Import components
import { TableCell, Typography } from 'components/elements';

// import helpers
import { useBookingLabelsTableCell } from './useBookingLabelsTableCell';

// Import styles
import { useStyles } from './BookingLabelsTableCell.styles';

export const BookingLabelsTableCell = ({ cell }) => {
	const { LABELS } = useBookingLabelsTableCell({
		cell,
	});

	const classes = useStyles();

	return (
		<TableCell cell={cell} {...cell.getCellProps()}>
			{LABELS.map((label) => (
				<Typography key={label} className={classes.label}>
					{label}
				</Typography>
			))}
		</TableCell>
	);
};

BookingLabelsTableCell.propTypes = {
	cell: object.isRequired,
};
