import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

// Import utils and helpers
import { TabsProvider } from 'components/context';
import { useTranslations } from 'components/utilities';
import { getTab, OFFICES_FORM_TABS } from './helpers';
import { useFormContent } from './useFormContent';

// Import components
import { Tabs, Typography } from 'components/elements';
import { BottomBar } from '../BottomBar';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { isTabError } = useFormContent();

	return (
		<>
			<Box className={classes.container}>
				<TabsProvider>
					<Typography className={classes.title}>
						{t('offices.form.add_title')}
					</Typography>
					<Tabs
						tabs={OFFICES_FORM_TABS}
						getTab={getTab}
						transKey="offices.form.tabs"
						tabClassName={classes.tab}
						getIcon={(index) =>
							isTabError(index) && (
								<Tooltip
									title={t('common.validation.missing_or_wrong_info')}
									placement="top"
								>
									<ErrorOutline color="error" />
								</Tooltip>
							)
						}
					/>
				</TabsProvider>
			</Box>
			<BottomBar />
		</>
	);
};
