import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { useExtraServices } from './useExtraServices';
import {
	getFields,
	DRIVER_PRICES_FIELDS,
	EXTRA_SERVICES_FIELDS,
	DELIVERY_AT_AIRPORT_FIELDS,
} from './helpers';

// Import components
import { PeriodPaymentsField } from 'components/elements';
import { PriceFieldArray } from './components';

export const ExtraServices = () => {
	const {
		t,
		i18n: { language: lang },
	} = useTranslations();
	const { renderFields } = useCommonFields();
	const {
		changeDeliveryDropWithDelivery,
		is_child_seat,
		car_delivery,
		submitting,
		driver,
		is_delivery_at_airport,
		airport,
	} = useExtraServices();

	const {
		DRIVER_FIELD,
		DELIVERY_FIELD,
		DELIVERY_DROP_FIELD,
		DELIVERY_AIRPORT_FIELD,
	} = EXTRA_SERVICES_FIELDS({
		changeDeliveryDropWithDelivery,
	});

	return (
		<Grid container spacing={3}>
			<Grid item md={6} xs={12}>
				{renderFields(DRIVER_FIELD)}
				{driver && DRIVER_PRICES_FIELDS.map(renderFields)}
			</Grid>

			<Grid item md={6} xs={12}>
				{renderFields(DELIVERY_FIELD)}
				{car_delivery && (
					<>
						<Typography variant="h6" gutterBottom>
							{t('offices.form.extra_services.message')}
						</Typography>
						<PriceFieldArray
							sectionName="options.car_delivery_options"
							submitting={submitting}
						/>
					</>
				)}
			</Grid>

			{car_delivery && renderFields(DELIVERY_DROP_FIELD)}

			<Grid item md={6} xs={12}>
				{renderFields(DELIVERY_AIRPORT_FIELD)}
				{is_delivery_at_airport &&
					DELIVERY_AT_AIRPORT_FIELDS({ airport, lang, t }).map(renderFields)}
			</Grid>

			<Grid item md={6} xs={12}>
				<PeriodPaymentsField
					labelText="offices.form.extra_services.child_seat"
					checkboxName="options.is_child_seat"
					fields={getFields()}
					isOpen={is_child_seat}
				/>
			</Grid>
		</Grid>
	);
};
