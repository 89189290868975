import { makeStyles } from '@material-ui/styles';

export const useGoogleMapsStyles = makeStyles(() => ({
	mapContainer: {
		height: 400,
	},
	mapLabel: {
		fontSize: 13,
		fontWeight: 600,
		marginBottom: '16px',
	},
}));
