import { SERVICES_TYPES } from 'helpers';

export const useBookingLabelsTableCell = ({ cell }) => {
	const {
		external_partner: externalPartner,
		is_created_by_agent: isAgent,
		corporate_name: corpName,
		service_type: serviceType,
	} = cell.row.original;

	const labelsList = {
		partner: externalPartner && `P-${externalPartner}`,
		corporate: corpName && `B-${corpName}`,
		chauffeur:
			serviceType === SERVICES_TYPES.CHAUFFEUR && corpName && `C-${corpName}`,
		isAgent: isAgent && 'A',
	};

	const LABELS = Object.values(labelsList).filter(Boolean);

	return { LABELS };
};
