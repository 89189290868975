import React from 'react';
import { CircularProgress, Collapse, Grid } from '@material-ui/core';
import { number, object } from 'prop-types';

// Import utils
import { useTranslations } from 'components/utilities';
import { useAdditionalOfficeFieldsRow } from './useAdditionalOfficeFieldsRow';

// Import components
import { ToggleButton, Typography } from 'components/elements';
import { OfficeInfo, ValidationChip } from '../../components';

// Import styles
import { useStyles } from './AdditionalOfficeFieldsRow.styles';

export const AdditionalOfficeFieldsRow = ({ index, fields }) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { isOpen, toggle, validationStatus, validationColor, validating } =
		useAdditionalOfficeFieldsRow({
			index,
			fields,
		});

	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				className={classes.container}
			>
				<Grid item>
					<Grid container spacing={1} alignItems="center">
						<Grid item>
							<Typography fontSize={18} fontWeight={600}>
								{`${t('common.fields.office')} #${index + 2} `}
								<span>{`(${t('common.fields.additional_office')})`}</span>
							</Typography>
						</Grid>
						<Grid item>
							{validating ? (
								<CircularProgress size={20} />
							) : (
								<ValidationChip
									label={validationStatus}
									color={validationColor}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<ToggleButton
						withoutText
						disableRipple
						onClick={toggle}
						isOpen={isOpen}
						color="inherit"
					/>
				</Grid>
			</Grid>
			<Collapse in={isOpen}>
				<OfficeInfo
					isAdditionalOffice
					section={`additional_offices.${index}`}
					className="additionalOfficeInfo"
				/>
			</Collapse>
		</>
	);
};

AdditionalOfficeFieldsRow.propTypes = {
	index: number.isRequired,
	fields: object.isRequired,
};
