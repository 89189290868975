// Import helpers
import { ACTION_MENU_POPUP_FIELD_TYPES, replaceEditUserUrl } from 'helpers';
import { deleteUser } from 'store/actions';

const { DELETE, EDIT } = ACTION_MENU_POPUP_FIELD_TYPES;

export const getPreviewFields = ({ cell, dispatch }) => [
	{ type: EDIT, editViewUrl: replaceEditUserUrl(cell.row.original.uuid) },
	{
		type: DELETE,
		deleteAction: () => deleteUser({ id: cell.row.original.uuid })(dispatch),
	},
];
