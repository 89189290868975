import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useForm } from 'react-final-form';
import services from 'services/services';

// Import helpers
import { createCarYearsBody } from './helpers';
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useCarYears = ({
	isGlobalEdit,
	vehicleList,
	manufacturer,
	model_name,
	initialManufacturer,
	initialModel,
	yearFieldName,
	lng,
	modelFieldName,
	models,
}) => {
	const { change } = useForm();

	const { CARS } = ENDPOINTS;

	const { MODEL_YEARS, SUPPLIER_MODEL_YEARS } = CARS;

	const path = isGlobalEdit ? MODEL_YEARS : SUPPLIER_MODEL_YEARS;

	const getFieldObj = isGlobalEdit ? model_name : manufacturer;
	const fieldName = getFieldObj[lng];

	const initialValue = isGlobalEdit ? initialModel : initialManufacturer;

	useEffect(() => {
		if (initialValue !== getFieldObj) {
			!isGlobalEdit && change(modelFieldName, null);
			change(yearFieldName, null);
		}
		refetch(getFieldObj);

		//eslint-disable-next-line
	}, [getFieldObj, vehicleList.length]);

	const body = createCarYearsBody({
		fieldName,
		models,
		model_name,
		isGlobalEdit,
		vehicleList,
		manufacturer,
		lng,
	});

	const { data, isFetching, isError, refetch } = useQuery(
		QUERY_KEYS.CARS.YEARS(JSON.stringify(body)),
		async () =>
			fieldName &&
			vehicleList.length !== 0 &&
			(await services.post(path, body)),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};

export default useCarYears;
