import React from 'react';
import { Grid, Box } from '@material-ui/core';

// Import utilities and helpers
import {
	useCommonFields,
	useProfile,
	useTranslations,
} from 'components/utilities';
import { FIELDS } from './helpers';

// Import components
import { Typography } from 'components/elements';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

export const ServiceTypes = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { renderFields } = useCommonFields();

	const { isAdmin } = useProfile();

	return (
		<Box>
			<Typography className={classes.sectionTitle}>
				{t('offices.form.service_type.title')}
			</Typography>
			<Grid container spacing={3}>
				{FIELDS({ isAdmin }).map(renderFields)}
			</Grid>
		</Box>
	);
};
