import React from 'react';
import { Divider } from '@material-ui/core';

// Import components
import {
	DeliveryHours,
	OfficeHours,
} from 'views/Offices/OfficesForm/components';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

export const DeliveryAndOfficeHours = () => {
	const classes = useStyles();

	return (
		<>
			<DeliveryHours />
			<Divider className={classes.divider} />
			<OfficeHours />
		</>
	);
};
