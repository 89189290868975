// Import helpers
import { FIELD_TYPES } from 'helpers';

export const COMMERCIAL_RECORD_FIELDS = ({ isCreate }) => [
	{
		type: FIELD_TYPES.NUMBER,
		name: 'commercial_record.number',
		label: 'offices.form.commercial_record.commercial_record_number',
		placeholder: 'e.g, 158965875487',
		inputLabelProps: {
			shrink: true,
		},
		required: true,
		min: 0,
		md: 6,
	},
	{
		type: FIELD_TYPES.IMAGE_FILE,
		label: 'offices.form.commercial_record.commercial_record_photo',
		name: 'commercial_record.file_path',
		fileNameField: 'commercial_record.file_name',
		previewFileState: 'commercial_record',
		imageFileButtonSize: 'medium',
		uploadButtonWidth: 12,
		required: !!isCreate,
		md: 6,
		uploadBtnFullWidth: false,
	},
];
