import { useQuery } from 'react-query';

// Import helpers and utils
import { ENDPOINTS, QUERY_KEYS } from 'helpers';
import services from 'services/services';

export const useTrainStationCollection = ({ enabled }) => {
	const { data, isError, isFetching } = useQuery(
		QUERY_KEYS.TRAIN_STATIONS.COLLECTION,
		async () => await services.get(ENDPOINTS.TRAIN_STATIONS.COLLECTION),
		{
			refetchOnWindowFocus: false,
			enabled,
		}
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
