import React from 'react';
import { useFormState } from 'react-final-form';
import { Field } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { DraftWysiwygFieldAdapter } from 'components/elements';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

export const SupplierNotes = () => {
	const classes = useStyles();

	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid container spacing={3}>
			<Grid item md={12}>
				<Typography className={classes.titleSmall}>
					{t('offices.form.supplier_notes.supplier_notes_en')}
				</Typography>
				<Field
					component={DraftWysiwygFieldAdapter}
					name="supplier_notes.en"
					disabled={submitting}
				/>
			</Grid>
			<Grid item md={12}>
				<Typography className={classes.titleSmall}>
					{t('offices.form.supplier_notes.supplier_notes_ar')}
				</Typography>
				<Field
					component={DraftWysiwygFieldAdapter}
					name="supplier_notes.ar"
					disabled={submitting}
				/>
			</Grid>
		</Grid>
	);
};
