import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { updateActiveUser } from 'store/actions';
import { useDispatch } from 'react-redux';

// Import helpers
import { COLUMN_ACCESSORS } from './../../columns';
import { getPreviewFields } from './helpers';

// Import components
import { ActiveTableCell, NewActionsMenuTableCell } from 'components/elements';

const { ACTIVE, ACTIONS } = COLUMN_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const dispatch = useDispatch();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveUser}
						cell={cell}
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={getPreviewFields({ cell, dispatch })}
						key={cell.column.id}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
