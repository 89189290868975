import { useEffect, useState } from 'react';

// import utils and helpers
import { getClosePaymentDetails } from 'store/actions';
import { useTranslations } from 'components/utilities';

export const useClosePaymentDetails = ({ booking }) => {
	const [details, setDetails] = useState({});
	const { t } = useTranslations();
	const {
		extra_day_price: additionalDaysPrice,
		additional_hour_price: additionalHoursPrice,
		additional_km_price: additionalKMsPrice,
		car_damage_price: carDamagePrice,
		full_refill_price: fuelRefillPrice,
		violations_price: violationsPrice,
		total_surcharge: totalPrice,
		paid_at: paidAt,
	} = details;

	useEffect(() => {
		getClosePaymentDetails({
			uuid: booking?.uuid,
			successCallback: (resp) => setDetails(resp.data),
			errorCallback: () => {},
		});
		// eslint-disable-next-line
	}, []);

	const prefix = 'bookings.preview_booking.invoice';

	const fields = [
		{ label: `${prefix}.total_charges`, accessor: 'total_charges' },
		{ label: `${prefix}.payment_method`, accessor: 'payment_method' },
		{ label: `common.fields.paid_at`, accessor: 'paid_at' },
	];

	additionalDaysPrice &&
		fields.push({
			label: `${prefix}.additional_days_charges`,
			accessor: 'additional_days_charges',
		});

	carDamagePrice &&
		fields.push({
			label: `${prefix}.car_damage_charges`,
			accessor: 'car_damage_charges',
		});

	fuelRefillPrice &&
		fields.push({
			label: `${prefix}.fuel_refill_charges`,
			accessor: 'fuel_refill_charges',
		});

	additionalHoursPrice &&
		fields.push({
			label: `${prefix}.additional_hours_charges`,
			accessor: 'additional_hours_charges',
		});

	violationsPrice &&
		fields.push({ label: `${prefix}.violations`, accessor: 'violations' });

	additionalKMsPrice &&
		fields.push({
			label: `${prefix}.additional_km_charges`,
			accessor: 'additional_km_charges',
		});

	const data = {
		total_charges: totalPrice,
		additional_days_charges: additionalDaysPrice,
		car_damage_charges: carDamagePrice,
		fuel_refill_charges: fuelRefillPrice,
		additional_hours_charges: additionalHoursPrice,
		violations: violationsPrice,
		additional_km_charges: additionalKMsPrice,
		payment_method: t(`${prefix}.payment_link`),
		paid_at: paidAt,
	};

	return { details, fields, data };
};
