// Import helpers
import { FIELD_TYPES } from 'helpers';

export const PRICE_FIELDS = ({
	name,
	getMenuItems,
	distances,
	index,
	prices,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'offices.form.extra_services.distance_delivery',
		name: `${name}.delivery_distance_uuid`,
		required: true,
		md: 4,
		options: getMenuItems(distances, index),
		keyExtractor: ([uuid]) => uuid,
		labelExtractor: ([, value]) => value,
	},
	{
		type: FIELD_TYPES.SELECT,
		label: 'offices.form.extra_services.price_car_delivery',
		name: `${name}.delivery_price_uuid`,
		required: true,
		md: 4,
		options: Object.entries(prices),
		keyExtractor: ([uuid]) => uuid,
		labelExtractor: ([, value]) => value,
	},
];
