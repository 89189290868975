import React from 'react';
import { Link } from 'react-router-dom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

// Import components
import { Button } from 'components/elements';

// mImport helpers
import { URLS } from 'components/routes';

export const OuterToolbarElements = () => {
	return (
		<Link to={URLS.addUserUrl}>
			<Button text="users.table.toolbar.add_user" icon={PersonAddIcon} />
		</Link>
	);
};
