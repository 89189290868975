import React from 'react';
import { Link } from 'react-router-dom';

// Import components
import { Button } from 'components/elements';

// Import helpers
import { URLS } from 'components/routes';

export const OuterToolbarElements = () => {
	return (
		<Link to={URLS.addCorporateUrl}>
			<Button text="b2b.corporate.table.toolbar.add_corporate" />
		</Link>
	);
};
