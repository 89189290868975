export const FIELD_TYPES = {
	DATE_AND_TIME: 'date_and_time',
	CHECKBOX: 'checkbox',
	IMAGE_FILE: 'image_file',
	PASSWORD: 'password',
	MULTI_SELECT: 'multi_select',
	MULTI_SELECT_WITH_SEARCH: 'multi_select_with_search',
	SELECT: 'select',
	NUMBER: 'number',
	DATE: 'date',
	TEXT: 'text',
	SWITCH: 'switch',
	FILE_UPLOADER: 'file_uploader',
	PHONE: 'phone',
	AUTOCOMPLETE: 'autocomplete',
	DATE_SELECT: 'date_select',
	PLATE_NUMBER: 'plate_number',
	RADIO_GROUP: 'radio_group',
	MULTI_SELECT_WITH_SEARCH_FILTER: 'multi_select_with_search_filter',
	MULTI_SELECT_FILTER: 'multi_select_filter',
	TIME: 'time',
	RICH_TEXT: 'rich_text',
};
