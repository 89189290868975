import React from 'react';
import { Form } from 'react-final-form';
import { Box, Divider, Grid, Typography } from '@material-ui/core';

// Import components
import { SliderSection } from './components';
import {
	ButtonProgress,
	LoadingWrapper,
	AlertMessage,
	Card,
} from 'components/elements';

// Import utils and helpers
import {
	SLIDER_OPTIONS,
	SLIDER_IMAGE,
	SLIDER_INFO,
	ADD_COMPANIES,
} from './helpers';
import { validationSchema } from './validation';
import { yupValidator } from 'helpers';
import { useTranslations } from 'components/utilities';
import { useSliderForm } from './useSliderForm';

// Import styles
import { useStyles } from './SliderForm.styles';

export const SliderForm = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const {
		handleOnSubmit,
		initialValues,
		title,
		isSliderFetching,
		isSliderError,
		isLoading,
		companies,
	} = useSliderForm();

	if (isSliderError) return <AlertMessage />;
	if (isSliderFetching)
		return <LoadingWrapper className={classes.loadingWrapper} />;

	return (
		<Box className={classes.container}>
			<Typography className={classes.title}>{title}</Typography>
			<Card lg={12}>
				<Form
					onSubmit={handleOnSubmit}
					initialValues={initialValues}
					validate={(values) =>
						yupValidator({
							values,
							validationSchema: validationSchema(),
						})
					}
					render={({ handleSubmit, values }) => (
						<form noValidate onSubmit={handleSubmit} id="sliderForm">
							<Grid container spacing={6}>
								<Grid item xs={12}>
									<SliderSection
										title="sliders.form.slider_info.title"
										fields={SLIDER_INFO}
									/>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<SliderSection
										title="sliders.form.slider_options.title"
										fields={SLIDER_OPTIONS}
									/>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<SliderSection
										title="sliders.form.slider_image.title"
										fields={SLIDER_IMAGE}
									/>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<SliderSection
										title="sliders.form.companies.add_companies"
										fields={ADD_COMPANIES({ values, companies })}
									/>
								</Grid>
							</Grid>
						</form>
					)}
				/>
			</Card>
			<Box className={classes.submitBar}>
				<ButtonProgress
					form="sliderForm"
					variant="contained"
					color="primary"
					type="submit"
					isLoading={isLoading}
					disabled={isLoading}
				>
					{t('common.buttons.save')}
				</ButtonProgress>
			</Box>
		</Box>
	);
};
