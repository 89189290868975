import { get } from 'lodash';
import { useFormState } from 'react-final-form';

// Import utilities and helpers
import { useAirportList, useTrainStationCollection } from 'queries';
import { useTranslations } from 'components/utilities';

export const useAdditionalFields = ({ section }) => {
	const {
		i18n: { language },
	} = useTranslations();
	const { values } = useFormState();

	const { submitting } = values;

	const isAirport = get(values, `${section}.is_airport`) || false;
	const isTrainStation = get(values, `${section}.is_train_station`) || false;

	const lat = values?.[section]?.lat || null;
	const lng = values?.[section]?.lng || null;

	const { data: airports } = useAirportList({ lat, lng });
	const { data: trainStations } = useTrainStationCollection({
		enabled: isTrainStation,
	});

	const getTerminals = (airports) => {
		const convertTerminalName = ({ airportName, name }) => {
			const bracketIndex = airportName.indexOf('(');
			const cutAirportName = airportName.substring(0, bracketIndex - 1);
			const resultAirportName =
				bracketIndex >= 0 ? cutAirportName : airportName;

			return resultAirportName + ' - ' + name[language];
		};

		return airports.reduce((acc, airport) => {
			const airportName = airport.name[language];
			const terminals = airport.terminals.flat().map(({ uuid, name }) => ({
				uuid,
				name: convertTerminalName({ airportName, name }),
			}));
			return [...acc, ...terminals];
		}, []);
	};

	const allTerminals = getTerminals(airports);

	return {
		trainStations,
		allTerminals,
		submitting,
		isAirport,
		values,
		isTrainStation,
	};
};
