import React from 'react';

// Import components
import {
	AdditionalOffices,
	DeliveryAndOfficeHours,
	ExtraServices,
	OfficeInfo,
	PublicHolidays,
	SupplierNotes,
} from 'views/Offices/OfficesForm/components';

export const OFFICES_FORM_TABS = {
	OFFICE_INFO: 'office_info',
	EXTRA_SERVICES: 'extra_services',
	DELIVERY_and_OFFICE_HOURS: 'delivery_and_office_hours',
	PUBLIC_HOLIDAYS: 'public_holidays',
	SUPPLIER_NOTES: 'supplier_notes',
	ADDITIONAL_OFFICES: 'additional_offices',
};

export const getTab = (currentTab) => {
	switch (currentTab) {
		case 0:
			return <OfficeInfo />;
		case 1:
			return <ExtraServices />;
		case 2:
			return <DeliveryAndOfficeHours />;
		case 3:
			return <PublicHolidays />;
		case 4:
			return <SupplierNotes />;
		default:
			return <AdditionalOffices />;
	}
};

export const TABS_SECTIONS = [
	[
		'bank',
		'service_type',
		'logo',
		'details',
		'location',
		'commercial_record',
		'commission',
		'driver_license',
		'company',
	],
	['options'],
	['delivery_hours', 'office_hours'],
	['holidays'],
	['supplier_notes'],
	['additional_offices'],
];
