import { bool, func } from 'prop-types';

// Import utils and helpers
import { useCommonFields, useProfile } from 'components/utilities';
import { useAdditionalFields } from './useAdditionalFields';
import { FIELDS } from './helpers';

export const AdditionalFields = ({ isAirportCheckboxDisabled, section }) => {
	const {
		allTerminals,
		submitting,
		isAirport,
		isTrainStation,
		trainStations,
		values,
	} = useAdditionalFields({ section });

	const { renderFields } = useCommonFields();

	const { isAdmin } = useProfile();

	return FIELDS({
		isAirportCheckboxDisabled,
		trainStations,
		allTerminals,
		submitting,
		section,
		values,
		isAdmin,
		isAirport,
		isTrainStation,
	}).map(renderFields);
};

AdditionalFields.propTypes = {
	isAirportCheckboxDisabled: bool,
	fieldDisabled: func,
};
