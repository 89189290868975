import { useEffect } from 'react';
import { useFormState, useForm } from 'react-final-form';
import moment from 'moment';

// Import helpers
import { FIELD_TYPES } from 'helpers';
import { transPrefix } from 'views/Bookings/CreateBooking/helpers';

export const useDateAndTime = () => {
	const {
		values: {
			period: { pick_date: pickDate, drop_date: dropDate, pick_time: pickTime },
		},
	} = useFormState();

	const { change } = useForm();

	const dropDateObj = moment(dropDate);
	const pickDateObj = moment(pickDate);

	const days =
		pickDate && dropDate
			? Math.ceil(dropDateObj.diff(pickDateObj, 'hours') / 24)
			: 0;

	const FIELDS = [
		{
			type: FIELD_TYPES.DATE,
			name: 'period.pick_date',
			inputLabel: `${transPrefix}.pick_up_date`,
			md: 6,
			minDate: new Date(),
		},
		{
			type: FIELD_TYPES.TIME,
			name: 'period.pick_time',
			inputLabel: `${transPrefix}.pick_up_time`,
			md: 6,
		},
		{
			type: FIELD_TYPES.DATE,
			name: 'period.drop_date',
			inputLabel: `${transPrefix}.drop_off_date`,
			disabled: !pickDate,
			minDate: pickDate && pickDateObj.add(1, 'day'),
			md: 6,
		},
		{
			type: FIELD_TYPES.TIME,
			name: 'period.drop_time',
			inputLabel: `${transPrefix}.drop_off_time`,
			disabled: true,
			md: 6,
		},
	];

	useEffect(() => {
		change('period.drop_time', pickTime);
		// eslint-disable-next-line
	}, [pickTime]);

	useEffect(() => {
		if (pickDate > dropDate) {
			change('period.drop_date', '');
		}
		// eslint-disable-next-line
	}, [pickDate]);

	return { FIELDS, days };
};
