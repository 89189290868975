import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { bool, string } from 'prop-types';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { refreshHolidays } from 'store/actions/helpers';
import { OFFICE_FORM_REFRESH_HOLIDAYS } from 'store/types';
import { officeFormSelector } from 'store/selectors';

// Import helpers
import { BASIC_FIELDS } from './helpers';

export const BaseDetails = ({ isAdditionalOffice = false, section = '' }) => {
	const { renderFields } = useCommonFields();
	const { values, modified } = useFormState();
	const { showCompanySelect } = values || {};
	const { companyList } = useSelector(officeFormSelector);

	const {
		i18n: { language: lang },
	} = useTranslations();
	const dispatch = useDispatch();

	useEffect(() => {
		if (modified['company.uuid']) {
			refreshHolidays(
				OFFICE_FORM_REFRESH_HOLIDAYS,
				values?.company?.uuid,
				dispatch
			);
		}
		// eslint-disable-next-line
	}, [values?.company?.uuid]);

	return (
		<Grid container direction="column" spacing={3}>
			{BASIC_FIELDS({
				companyList,
				showCompanySelect,
				isAdditionalOffice,
				lang,
				section,
			}).map(renderFields)}
		</Grid>
	);
};

BaseDetails.propTypes = {
	isAdditionalOffice: bool,
	section: string,
};
