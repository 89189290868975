// Import helpers
import {
	BOOKING_CANCELLATION_STATES,
	convertFormDates,
	getFilterIndex,
	removePreviousFilterValues,
	spreadFilterArray,
} from 'helpers';

export const initialValues = {
	is_compensation: 'all',
	companies: ['all'],
	pick_date: null,
	pick_date_to: null,
	created_at: null,
	created_at_to: null,
	drop_date: null,
	drop_date_to: null,
	cancel_reason_status: BOOKING_CANCELLATION_STATES.REVIEW,
	cancel_reason_created_at: null,
	cancel_reason_created_at_to: null,
};

export const formatValues = (values, { inputColumns }) => {
	const defaultCompanyFilterIndex = 14;
	const companyFilterIndex = getFilterIndex({
		inputColumns,
		filterName: 'company_uuids',
		defaultFilterIndex: defaultCompanyFilterIndex,
	});

	const newValues = removePreviousFilterValues({
		values,
		inputColumns,
		filterName: 'company_uuids',
		defaultFilterIndex: defaultCompanyFilterIndex,
	});

	return {
		...newValues,
		companies: values.companies,
		cancel_reason_status:
			values.cancel_reason_status === 'all'
				? null
				: values.cancel_reason_status,
		is_compensation:
			values.is_compensation === 'all' ? null : values.is_compensation,
		...convertFormDates(values, [
			'pick_date',
			'drop_date',
			'created_at',
			'cancel_reason_created_at',
		]),
		...spreadFilterArray({
			arrayElements: values.companies,
			filterIndex: companyFilterIndex,
			defaultFilterIndex: defaultCompanyFilterIndex,
			inputColumns,
		}),
	};
};
