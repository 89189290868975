import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES, SLIDER_TYPES } from 'helpers';

const multiSelectLabelExtractor = (options, currentUuid, lang) =>
	currentUuid === 'all'
		? null
		: options.find(({ uuid }) => uuid === currentUuid)?.name?.[lang];

export const INITIAL_VALUES = {
	image: {
		file_path: '',
		file_name: '',
	},
	details: {
		locale: '',
		name: '',
		title: '',
		position: '',
		show_in: '',
		company_uuid: [],
	},
};

export const locales = [
	{
		name: 'Polish',
		locale: 'pl',
	},
	{
		name: 'English',
		locale: 'en',
	},
	{
		name: 'Arabic',
		locale: 'ar',
	},
];

export const SLIDER_INFO = [
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.name',
		name: 'details.name',
		placeholder: i18next.t('sliders.form.slider_info.name_placeholder'),
		required: true,
		additionalText: i18next.t('sliders.form.slider_info.additional_name_text'),
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.title',
		name: 'details.title',
		placeholder: i18next.t('sliders.form.slider_info.title_placeholder'),
		required: true,
		additionalText: i18next.t('sliders.form.slider_info.additional_title_text'),
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.description',
		name: 'details.description',
		placeholder: i18next.t('sliders.form.slider_info.description_placeholder'),
		required: true,
		additionalText: i18next.t(
			'sliders.form.slider_info.additional_description_text'
		),
		multiline: true,
		minRows: 4,
	},
];

export const SLIDER_OPTIONS = [
	{
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.locale',
		name: 'details.locale',
		options: locales,
		keyExtractor: ({ locale }) => locale,
		labelExtractor: ({ name }) => name,
		required: true,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.position',
		name: 'details.position',
		required: true,
	},
];

export const SLIDER_IMAGE = [
	{
		type: FIELD_TYPES.IMAGE_FILE,
		label: 'common.fields.upload_the_image',
		fileNameField: 'image.file_name',
		previewFileState: 'image',
		name: 'image.file_path',
		imageFileSize: 'large',
		imageFileUploadButtonWidth: 12,
		required: true,
	},
];

export const ADD_COMPANIES = ({ values, companies }) => [
	{
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
		currentMultiSelectValues: values?.details.company_uuid,
		labelExtractor: ({ name }) => name?.[i18next.language],
		keyExtractor: ({ uuid }) => uuid,
		searchExtractor: 'children[1]',
		multiSelectLabelExtractor: (options, currentUuid) =>
			multiSelectLabelExtractor(options, currentUuid, i18next.language),
		showAllOption: true,
		options: companies,
		label: i18next.t('sliders.form.companies.choose_companies'),
		name: 'details.company_uuid',
		required: true,
	},
];

export const getTitle = ({ sliderType, sliderUuid }) => {
	if (sliderType === SLIDER_TYPES.WEBSITE) {
		return sliderUuid
			? 'sliders.form.edit_website_slider'
			: 'sliders.form.add_new_website_slider';
	} else {
		return sliderUuid
			? 'sliders.form.edit_mobile_slider'
			: 'sliders.form.add_new_mobile_slider';
	}
};
