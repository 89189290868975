import React, { useState, useEffect } from 'react';
import { func, number } from 'prop-types';
import { Box } from '@material-ui/core';

export const TabPanel = ({ currentTab, getTab, ...rest }) => {
	const [renderedTabs, setRenderedTabs] = useState({});

	useEffect(() => {
		// Render the current tab if it hasn't been rendered yet to prevent re-rendering the same tab
		if (!renderedTabs[currentTab]) {
			setRenderedTabs((prev) => ({
				...prev,
				[currentTab]: getTab(currentTab),
			}));
		}
		// eslint-disable-next-line
	}, [currentTab, getTab]);

	return (
		<Box
			role="tabpanel"
			id={`tabpanel-${currentTab}`}
			aria-labelledby={`tab-${currentTab}`}
			{...rest}
		>
			{Object.keys(renderedTabs).map((tabIndex) => (
				<Box key={tabIndex} hidden={currentTab !== Number(tabIndex)}>
					{renderedTabs[tabIndex]}
				</Box>
			))}
		</Box>
	);
};

TabPanel.propTypes = {
	currentTab: number.isRequired,
	getTab: func.isRequired,
};
