import React from 'react';
import { Grid } from '@material-ui/core';
import { string } from 'prop-types';

// Import utils and helpers
import { useCommonFields } from 'components/utilities';

// Import helpers
import { CONTACTS_FIELDS } from './helpers';

export const Contacts = ({ section }) => {
	const { renderFields } = useCommonFields();

	return (
		<Grid container direction="column" spacing={3}>
			{CONTACTS_FIELDS({ section }).map(renderFields)}
		</Grid>
	);
};

Contacts.propTypes = {
	section: string,
};
