// Import helpers
import { FIELD_TYPES } from 'helpers';

const multiSelectLabelExtractor = (options, currentUuid) =>
	currentUuid === 'all'
		? null
		: options.find(({ uuid }) => uuid === currentUuid)?.name;

export const FIELDS = ({
	isAirportCheckboxDisabled,
	allTerminals,
	trainStations,
	submitting,
	section,
	values,
	isAdmin,
	isAirport,
	isTrainStation,
}) => [
	{
		type: FIELD_TYPES.CHECKBOX,
		name: `${section}.is_airport`,
		label: 'offices.form.location.is_airport',
		disabled: submitting || isAirportCheckboxDisabled || !isAdmin,
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.MULTI_SELECT,
		name: `${section}.airport.terminals`,
		label: 'kiosk.management.form.terminals.title',
		currentMultiSelectValues: values?.[section]?.airport?.terminals,
		multiSelectLabelExtractor,
		options: allTerminals,
		isHidden: !isAirport,
		disabled: submitting || !isAdmin,
		showAllOption: true,
		md: 6,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		name: `${section}.is_train_station`,
		label: 'offices.form.location.is_train_station',
		disabled: submitting || !isAdmin,
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: `${section}.train_station_uuid`,
		label: 'common.fields.train_station',
		isHidden: !isTrainStation,
		options: trainStations,
		md: 6,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name,
		disabled: submitting || !isAdmin,
	},
];
