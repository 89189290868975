import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, List, ListItem, Typography } from '@material-ui/core';

// Import utils and helpers
import { useCommonFields } from 'components/utilities';
import { HOLIDAY_CHECKBOX_FIELD } from './helpers';

// Import store
import { officeFormSelector } from 'store/selectors';

export const PublicHolidays = () => {
	const { holidays } = useSelector(officeFormSelector);

	const { renderFields } = useCommonFields();

	return (
		<List>
			{holidays.map(({ uuid, name, date_from, date_to }) => (
				<ListItem key={uuid}>
					<Grid container alignItems="center">
						{renderFields(HOLIDAY_CHECKBOX_FIELD({ name }))}
						<Grid item xs={4}>
							<Typography component="span" variant="h6">
								{date_from}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography component="span" variant="h6">
								{date_to}
							</Typography>
						</Grid>
					</Grid>
				</ListItem>
			))}
		</List>
	);
};
