import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { COMMERCIAL_RECORD_FIELDS } from './helpers';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

export const CommercialRecord = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	const { pathname } = useLocation();
	const isCreate = pathname.includes('create');

	return (
		<Box>
			<Typography className={classes.sectionTitle}>
				{t('offices.form.commercial_record.title')}
			</Typography>
			<Grid container direction="column" spacing={2}>
				{COMMERCIAL_RECORD_FIELDS({ isCreate }).map(renderFields)}
			</Grid>
		</Box>
	);
};
