import React from 'react';

// Import utils and helpers
import { TableRPC, useProfile } from 'components/utilities';
import { columns, COLUMNS_ACCESSORS } from './columns';
import { BOOKINGS_PAGES_TYPES } from 'helpers';

// Import components
import { Export, TableBodyRow, Filters, AdditionalInfo } from './components';

// Import store
import { fetchCorporateBookings } from 'store/actions';

// Import styles
import { useStyles } from './CorporateBookingsList.styles';

export const CorporateBookingsList = () => {
	const { isCorporateCoordinator } = useProfile();
	const classes = useStyles();

	const location = new URLSearchParams(window.location.search);
	const customerUuid = location.get('customerUuid');

	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			title="nav.corporate.corporate_bookings"
			columns={columns({ classes, isCorporateCoordinator })}
			filtersBar={<Filters />}
			tableBodyRow={TableBodyRow}
			export={<Export pageType={BOOKINGS_PAGES_TYPES.MAIN} />}
			fetchDataTableAction={(options) =>
				fetchCorporateBookings({ ...options, customerUuid })
			}
			additionalNavigationElements={
				!isCorporateCoordinator ? (
					<AdditionalInfo
						showAssignedDriverAdditional={true}
						showDeliveryStatusAdditional={true}
					/>
				) : null
			}
		/>
	);
};
