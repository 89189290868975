import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		'& .MuiSvgIcon-root': {
			margin: 0,
			width: '32px',
			height: '32px',
		},
		'& .MuiButton-startIcon': {
			margin: 0,
		},
		'& [class*="collapseBtn"]': {
			width: '32px',
			height: '32px',
			minWidth: '32px',
		},
	},
}));
