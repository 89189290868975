import React from 'react';
import { Box } from '@material-ui/core';

// Import utilities and helpers
import {
	useTranslations,
	useCommonFields,
	useProfile,
} from 'components/utilities';
import { useCommissionFields } from './useCommissionFields';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

// Import components
import { Grid, Typography } from 'components/elements';

export const CommissionPercentage = () => {
	const classes = useStyles();

	const { t } = useTranslations();
	const { renderFields } = useCommonFields();
	const { isAdmin } = useProfile();
	const { FIELDS } = useCommissionFields();

	if (!isAdmin) return null;

	return (
		<Box>
			<Typography className={classes.sectionTitle}>
				{t('offices.form.commission_percentage.title')}
			</Typography>
			<Grid container spacing={3}>
				{FIELDS.map(renderFields)}
			</Grid>
		</Box>
	);
};
