// Import helpers
import { spreadFilterArrayNoColumn } from 'helpers';

export const initialValues = {
	company_uuid: ['all'],
	maker_name: ['all'],
	model_name: ['all'],
	year: ['all'],
};

export const formatValues = (values) => {
	return {
		...spreadFilterArrayNoColumn({
			arrayElements: values.company_uuid,
			filterName: 'company_uuid',
		}),

		company_uuid: values.company_uuid.includes('all')
			? null
			: values.company_uuid,
		maker_name: values.maker_name.includes('all') ? null : values.maker_name,
		model_name: values.model_name.includes('all') ? null : values.model_name,
		year: values.year.includes('all') ? null : values.year,
	};
};
