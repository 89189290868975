import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	gridRemove: {
		display: 'flex',
		alignItems: 'center',
	},
	buttonRemove: {
		background: theme.palette.error.main,
		color: theme.palette.white,

		'&:hover': {
			background: theme.palette.error.main,
			color: theme.palette.white,
		},
	},
}));
