import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 18,
		marginBottom: theme.spacing(2),
		fontWeight: 500,
	},
	message: {
		fontSize: 14,
		marginBottom: theme.spacing(4),
	},
	divider: {
		width: '100%',
		margin: theme.spacing(5, 0),
	},
}));
