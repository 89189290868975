import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Import store
import { submitSliderForm } from 'store/actions';

// Import utils and helpers
import { useMessage, useTranslations } from 'components/utilities';
import { useSliderQuery } from 'queries';
import { getTitle, INITIAL_VALUES } from './helpers';
import { useCompanies } from 'queries';

export const useSliderForm = () => {
	const [isLoading, setLoading] = useState(false);
	const { id: sliderUuid } = useParams();
	const { message } = useMessage();
	const { t } = useTranslations();
	const { goBack } = useHistory();
	const { data: companies } = useCompanies();

	const location = new URLSearchParams(window.location.search);
	const sliderType = location.get('show_in');

	const {
		data: sliderData,
		isError: isSliderError,
		isFetching: isSliderFetching,
	} = useSliderQuery({ sliderUuid });

	const initialValues = sliderUuid ? sliderData : INITIAL_VALUES;

	const title = t(getTitle({ sliderType, sliderUuid }));

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setLoading(true);

		const body = {
			...values,
			details: {
				...values.details,
				show_in: sliderType,
			},
		};

		await submitSliderForm({
			callbackSuccess: () => {
				message.success(t('common.messages.successfully_done'));
				setLoading(false);
				goBack();
			},
			errorCallback: (error) => {
				if (error.response) {
					apiErrors = error.response.data.errors;
				}
				message.error(t('common.messages.error_message'));
				setLoading(false);
			},
			itemId: sliderUuid,
			values: body,
		});

		return apiErrors;
	};

	return {
		handleOnSubmit,
		initialValues,
		title,
		isSliderFetching,
		isSliderError,
		isLoading,
		companies,
	};
};
