// Import helpers
import { FIELD_TYPES, POPULAR_BANKS_LIST } from 'helpers';

export const BANK_INFO_FIELDS = ({ useCompanyBank }) => [
	{
		type: FIELD_TYPES.CHECKBOX,
		name: 'bank.use_company_bank',
		label: 'offices.form.bank_info.same_as_company',
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'bank.name',
		label: 'offices.form.bank_info.name',
		md: 6,
		required: true,
		isHidden: !!useCompanyBank,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'bank.bank_name',
		label: 'offices.form.bank_info.bank_name',
		options: POPULAR_BANKS_LIST,
		keyExtractor: (value) => value,
		labelExtractor: (value) => value,
		md: 6,
		required: true,
		isHidden: !!useCompanyBank,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'bank.number',
		label: 'offices.form.bank_info.account_number',
		md: 6,
		required: true,
		isHidden: !!useCompanyBank,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'bank.iban_number',
		label: 'offices.form.bank_info.iban_number',
		md: 6,
		required: true,
		isHidden: !!useCompanyBank,
	},
];
