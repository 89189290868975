import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { BANK_INFO_FIELDS } from './helpers';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

export const BankInfo = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const {
		values: {
			bank: { use_company_bank: useCompanyBank },
		},
	} = useFormState();

	const { renderFields } = useCommonFields();

	return (
		<Box>
			<Typography className={classes.sectionTitle}>
				{t('offices.form.bank_info.title')}
			</Typography>
			<Grid container direction="column" spacing={2}>
				{BANK_INFO_FIELDS({ useCompanyBank }).map(renderFields)}
			</Grid>
		</Box>
	);
};
