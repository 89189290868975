// Import helpers
import { FIELD_TYPES } from 'helpers';

export const DRIVER_LICENSE_FIELDS = [
	{
		type: FIELD_TYPES.CHECKBOX,
		name: 'driver_license.international_driver_licence',
		label: 'offices.form.driver_licence.international_diver_licence',
		md: 6,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		name: 'driver_license.foreign_driver_licence',
		label: 'offices.form.driver_licence.foreign_driver_licence',
		md: 6,
	},
];
