import React, { Fragment } from 'react';
import { string } from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { Grid, Button } from '@material-ui/core';

// Import utilities
import { useCommonFields, useTranslations } from 'components/utilities';
import { usePriceFieldArray } from './usePriceFieldArray';

// Import styles
import { useStyles } from './styles';
import { PRICE_FIELDS } from './helpers';

export const PriceFieldArray = ({ sectionName }) => {
	const { t } = useTranslations();
	const { renderFields } = useCommonFields();

	const classes = useStyles();

	const {
		isAddButtonVisible,
		handlePushItem,
		getMenuItems,
		distances,
		prices,
	} = usePriceFieldArray(sectionName);

	return (
		<Grid container spacing={3}>
			<FieldArray name={sectionName}>
				{({ fields }) =>
					fields.map((name, index) => (
						<Fragment key={name}>
							{PRICE_FIELDS({
								name,
								getMenuItems,
								distances,
								index,
								prices,
							}).map(renderFields)}

							<Grid className={classes.gridRemove} item md={4} xs={12}>
								<Button
									className={classes.buttonRemove}
									variant="contained"
									onClick={() => fields.remove(index)}
									style={{ cursor: 'pointer' }}
								>
									X
								</Button>
							</Grid>
						</Fragment>
					))
				}
			</FieldArray>
			{isAddButtonVisible && (
				<Grid item md={12} xs={12}>
					<Button
						type="button"
						color="primary"
						variant="contained"
						onClick={handlePushItem}
					>
						{t('common.buttons.add')}
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

PriceFieldArray.propTypes = {
	sectionName: string.isRequired,
};
