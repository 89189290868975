import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';

// Import utilities
import { CHIP_COLORS } from 'helpers';

export const useAdditionalOfficeFieldsRow = ({ index, fields }) => {
	const { errors, validating } = useFormState();
	const isLastIndex = index === fields.length - 1;

	const [isOpen, setIsOpen] = useState(!!isLastIndex);

	const isValid = !errors?.additional_offices?.[index];

	const validationStatus = `common.validation.${
		isValid ? 'completed_info' : 'missing_info'
	}`;

	const validationColor = isValid ? CHIP_COLORS.SUCCESS : CHIP_COLORS.ERROR;

	useEffect(() => {
		setIsOpen(isLastIndex);
		// eslint-disable-next-line
	}, [fields.length, index]);

	const toggle = () => setIsOpen(!isOpen);

	return {
		isOpen,
		toggle,
		validationStatus,
		validationColor,
		validating,
	};
};
