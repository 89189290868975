import React from 'react';
import { Card, CardContent, Tab, Tabs as MuiTabs } from '@material-ui/core';
import { func, node, number, shape, string } from 'prop-types';
import clsx from 'clsx';

// Import context
import { useTabsProvider } from 'components/context';

// Import components
import { TabPanel } from './components';

// Import utils and helpers
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './Tabs.styles';

export const Tabs = ({
	tabs,
	cardClassName,
	tabClassName,
	getTab,
	textColor = 'primary',
	transKey,
	containerClassName = '',
	getIcon = () => null,
	icon = null,
}) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { a11yProps, currentTab, handleChange } = useTabsProvider();

	const tabsArray = Object.keys(tabs);

	return (
		<Card className={clsx([classes.card, cardClassName])}>
			<MuiTabs
				value={currentTab}
				onChange={handleChange}
				aria-label="tabs"
				textColor={textColor}
				variant="scrollable"
				TabIndicatorProps={{ hidden: true }}
				className={containerClassName}
			>
				{tabsArray.map((key, index) => (
					<Tab
						key={key}
						label={t(`${transKey}.${key.toLocaleLowerCase()}`)}
						className={clsx([classes.tabHeader, tabClassName])}
						{...a11yProps(index)}
						icon={icon || getIcon(index) || null}
					/>
				))}
			</MuiTabs>

			<CardContent className={classes.cardContent}>
				<TabPanel currentTab={currentTab} getTab={getTab} />
			</CardContent>
		</Card>
	);
};

Tabs.propTypes = {
	tabs: shape({
		index: number,
		label: string,
		content: node,
	}).isRequired,
	cardClassName: string,
	tabClassName: string,
	textColor: string,
	getTab: func.isRequired,
	transKey: string.isRequired,
	containerClassName: string,
	getIcon: func,
	icon: node,
};
