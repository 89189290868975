import React from 'react';
import { string } from 'prop-types';

// Import helpers
import { formatValues, initialValues } from '../Filters/helpers';

// Import components
import { FilterFields } from '../Filters/components';
import { TableExportFilters } from 'components/elements';

export const ExportFilters = ({ pageType }) => {
	return (
		<TableExportFilters
			initialValues={initialValues}
			formatValues={formatValues}
		>
			<FilterFields pageType={pageType} isExport />
		</TableExportFilters>
	);
};

ExportFilters.propTypes = {
	pageType: string,
};
