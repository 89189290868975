import React from 'react';
import { Box } from '@material-ui/core';

// Import components
import { Typography } from 'components/elements';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

// Import utils and helpres
import { useCommonFields, useTranslations } from 'components/utilities';
import { FIELDS } from './helpers';

export const OfficeLogo = () => {
	const classes = useStyles();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();

	return (
		<Box>
			<Typography className={classes.sectionTitle}>
				{t('offices.form.office_logo')}
			</Typography>

			{FIELDS.map(renderFields)}
		</Box>
	);
};
