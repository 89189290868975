// Import helpers
import { FIELD_TYPES } from 'helpers';

export const BASIC_FIELDS = ({
	companyList,
	showCompanySelect,
	isAdditionalOffice,
	lang,
	section,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.company',
		name: 'company.uuid',
		md: 6,
		options: companyList,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[lang],
		isHidden: !showCompanySelect || isAdditionalOffice,
		required: true,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'offices.form.details.office_english_name',
		name: `${section}.details.name.en`,
		md: 6,
		required: true,
		helperText: 'common.fields.company_name_validation_hint',
		placeholder: 'e.g, SAP - Al Malaz',
		inputLabelProps: {
			shrink: true,
		},
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'offices.form.details.office_arabic_name',
		name: `${section}.details.name.ar`,
		md: 6,
		required: true,
		helperText: 'common.fields.company_name_validation_hint',
		placeholder: 'مثال, ساب - الملز',
		inputLabelProps: {
			shrink: true,
		},
	},
];
