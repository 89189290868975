import { alpha } from '@material-ui/core';

// Import helpers and palette
import { INPUT_BACKGROUND_COLOR } from 'helpers';
import palette from '../palette.js';

export default {
	root: {
		minHeight: '49px',

		'& ~ .MuiFormHelperText-root': {
			margin: '12px 0 0 0',
			fontSize: 14,
			color: palette?.text[1],
		},
	},
	input: {
		backgroundColor: INPUT_BACKGROUND_COLOR,
		borderRadius: 8,
		color: palette?.text.inputText,
		fontWeight: 600,
		fontSize: 14,

		'&::placeholder': {
			fontWeight: 400,
			color: palette?.text[1],
			opacity: 1,
		},

		'@media (max-width: 960px)': {
			backgroundColor: palette?.white,
			borderRadius: 6,
			color: alpha(palette?.black, 0.43),
			minHeight: '20px',
			maxWidth: '100%',
			fontWeight: 400,
			fontSize: 12,
		},
	},
	adornedStart: {
		backgroundColor: INPUT_BACKGROUND_COLOR,
	},
	adornedEnd: {
		backgroundColor: INPUT_BACKGROUND_COLOR,
		'@media (max-width: 960px)': {
			backgroundColor: palette?.white,
		},
	},
	multiline: {
		backgroundColor: INPUT_BACKGROUND_COLOR,
	},
};
