import React from 'react';
import { Field } from 'react-final-form';
import { number, string } from 'prop-types';
import { Checkbox, CircularProgress, Grid, MenuItem } from '@material-ui/core';

// Import utilities
import { useMultiOfficeSelect } from './useMultiOfficeSelect';

// Import components
import { MultiselectFiledAdapter } from 'components/elements';

export const MultiOfficeSelect = ({
	name,
	label,
	xs = 6,
	md = 6,
	externalCompanyUuid = '',
}) => {
	const {
		findOfficeLabel,
		currentOffices,
		language,
		offices,
		isDisabled,
		isFetching,
	} = useMultiOfficeSelect({ name, externalCompanyUuid });

	return (
		<Grid container item xs={xs} md={md} alignItems="center">
			{isFetching ? (
				<CircularProgress size={24} />
			) : (
				<Field
					format={(value) => (Array.isArray(value) ? value : [])}
					label={label}
					component={MultiselectFiledAdapter}
					labelextractor={findOfficeLabel}
					disabled={isDisabled}
					variant="outlined"
					margin="dense"
					type="select"
					name={name}
					fullWidth
					required
				>
					{offices.map(({ uuid, name }) => (
						<MenuItem key={uuid} value={uuid}>
							<Checkbox
								checked={currentOffices?.includes(uuid)}
								color="primary"
							/>
							{name[language]}
						</MenuItem>
					))}
				</Field>
			)}
		</Grid>
	);
};

MultiOfficeSelect.propTypes = {
	externalCompanyUuid: string,
	name: string.isRequired,
	label: string.isRequired,
	xs: number,
	md: number,
};
