// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

// Import components
import { Statistics } from './components';

export const TOOLBAR_CONTENT = () => [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Statistics,
		customKey: 'statistics',
	},
];
