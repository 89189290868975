import i18next from 'i18next';

export const columns = [
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: 'individual_number',
		valueExtractor: (row) => row.individual_number,
	},
	{
		Header: 'bookings.table.headers.topic',
		accessor: 'topic',
		valueExtractor: (row) => row.operation_topics.join(', '),
	},
	{
		Header: 'bookings.table.headers.compensation_status',
		accessor: 'compensation_status',
	},
	{
		Header: 'bookings.table.headers.urgent_status',
		accessor: 'urgent_assistance_status_list',
		valueExtractor: (row) => {
			const urgentStatusesList = row.urgent_assistance_status_list;
			return urgentStatusesList.join(',  ');
		},
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: 'customer_name',
	},
	{
		Header: 'bookings.table.headers.customer_type',
		accessor: 'customer_type',
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
	},
	{
		Header: 'bookings.table.headers.office_name',
		accessor: 'office_name',
	},
	{
		Header: 'bookings.table.headers.office_city',
		accessor: 'office_city',
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: 'pick_date',
	},
	{
		Header: 'bookings.table.headers.pick_time',
		accessor: 'pick_time',
	},
	{
		Header: 'bookings.table.headers.created_time',
		accessor: 'created_at',
	},
	{
		Header: 'bookings.table.headers.status',
		accessor: 'state',
	},
	{
		Header: 'bookings.table.headers.cancellation_reason',
		accessor: 'cancel_description',
	},
	{
		Header: 'bookings.table.headers.delivery',
		accessor: 'delivery_price',
	},
	{
		Header: 'bookings.table.headers.payment_method',
		accessor: 'payment.method',
	},
	{
		Header: 'bookings.table.headers.customer_nationality',
		accessor: 'customer_nationality',
	},
	{
		Header: 'bookings.table.headers.gender',
		accessor: 'gender',
	},
	{
		Header: 'bookings.table.headers.drop_date',
		accessor: 'drop_date',
	},
	{
		Header: 'bookings.table.headers.pick_location',
		accessor: 'pick_location',
	},
	{
		Header: 'bookings.table.headers.drop_location',
		accessor: 'drop_location',
	},
	{
		Header: 'bookings.table.headers.total_base_nett_price',
		accessor: 'total_base_nett_price',
	},
	{
		Header: 'bookings.table.headers.total_discounted_gross_price',
		accessor: 'total_discounted_gross_price',
	},
	{
		Header: 'bookings.table.headers.vehicle',
		accessor: 'vehicle',
	},
	{
		Header: 'bookings.table.headers.booking_no',
		accessor: 'customer_no_booking',
	},
	{
		Header: 'bookings.table.headers.booking_time',
		accessor: 'respond_time',
	},
	{
		Header: 'bookings.table.headers.duration',
		accessor: 'response_time',
		valueExtractor: (row) =>
			`${row.response_time} ${i18next.t('bookings.table.common.minutes')}`,
	},
	{
		Header: 'bookings.table.headers.updated_time',
		accessor: 'updated_at',
	},
	{
		Header: 'bookings.table.headers.approved',
		accessor: 'approved',
		valueExtractor: (row) => i18next.t(`common.${row.approved ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.table.headers.admins_comment',
		accessor: 'admin_discuss',
		valueExtractor: (row) => {
			const adminComments = row.admin_discuss || [];

			const adminCommentsString = adminComments
				.map(
					({ added_at, content, full_name }) =>
						`${full_name}: (${added_at}): ${content}`
				)
				.join(', \n');

			return adminCommentsString;
		},
	},
	{
		Header: 'bookings.table.headers.license_number',
		accessor: 'license_number',
	},
	{
		Header: 'bookings.table.headers.compensated',
		accessor: 'compensated',
		valueExtractor: (row) =>
			row.is_compensation ? `${row.compensation_amount} SAR` : 'N/A',
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: 'office_rating',
	},
	{
		Header: 'bookings.table.headers.rating_reason',
		accessor: 'rating_reason',
		valueExtractor: (row) => {
			const { old: oldReason, new: newReason } = row.rating_reasons || {};

			const reasonKey = `reason_${i18next.language}`;

			const oldReasonString = oldReason
				? `${i18next.t(`common.old`)}: ${oldReason?.[reasonKey]}`
				: '';
			const newReasonString = newReason
				? `${i18next.t(`common.new`)}: ${newReason?.[reasonKey]}`
				: '';

			const reasons = [oldReasonString, newReasonString].filter(Boolean);

			return reasons.join(', ');
		},
	},
	{
		Header: 'bookings.table.headers.customer_email',
		accessor: 'customer_email',
	},
	{
		Header: 'bookings.table.headers.office_address',
		accessor: 'office_address',
	},
	{
		Header: 'bookings.table.headers.settlement_status',
		accessor: 'settlement_status',
		valueExtractor: (row) =>
			i18next.t(`common.${row.is_settled ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.table.headers.kiosk',
		accessor: 'is_kiosk',
		valueExtractor: (row) => i18next.t(`common.${row.is_kiosk ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.table.headers.compensation_method',
		accessor: 'compensation.method',
	},
	{
		Header: 'bookings.table.headers.iban',
		accessor: 'compensation.iban',
	},
	{
		Header: 'bookings.table.headers.urgent_assistance_reason',
		accessor: 'urgentAssistance',
		valueExtractor: (row) => {
			const urgentAssistanceReason =
				row?.urgentAssistance?.name?.[i18next.language];

			return urgentAssistanceReason;
		},
	},
	{
		Header: 'bookings.table.headers.marked_by',
		accessor: 'urgent_assistance_marked_by_list',
		valueExtractor: (row) => {
			const markedByList = row.urgent_assistance_marked_by_list;
			return markedByList.join(',  ');
		},
	},
	{
		Header: 'bookings.table.headers.validated_by',
		accessor: 'compensation.compensation_validated_by',
	},
	{
		Header: 'bookings.table.headers.compensated_by',
		accessor: 'compensation.compensation_maker_author_name',
	},
	{
		Header: 'bookings.table.headers.number_of_assigned_urgent',
		accessor: 'urgent_assistance_count',
	},
	{
		Header: 'bookings.table.headers.cancellation_status',
		accessor: 'cancel_reason.status',
	},
	{
		Header: 'bookings.table.headers.old_cancellation_reason',
		accessor: 'old_cancellation_reason',
		valueExtractor: (row) => {
			const oldCancellationReason =
				row.cancel_reason?.old_name?.[i18next.language];

			return oldCancellationReason;
		},
	},
	{
		Header: 'bookings.table.headers.approved_by',
		accessor: 'approved_by',
	},
	{
		Header: 'bookings.table.headers.rejected_by',
		accessor: 'rejected_by',
	},
	{
		Header: 'bookings.table.headers.reported_by',
		accessor: 'urgent_assistance_reported_by',
	},
	{
		Header: 'bookings.table.headers.assigned_urgent_date',
		accessor: 'urgent_assistance_created_at',
	},
];
