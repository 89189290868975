// Import helpers and utils
import { ACTION_MENU_POPUP_FIELD_TYPES } from 'helpers';
import { replaceEditBookingUrl } from 'helpers';

const { EDIT, BOOKINGS_HISTORY, TRANSACTIONS_HISTORY, PRINT_BOOKING } =
	ACTION_MENU_POPUP_FIELD_TYPES;

export const getActions = ({ uuid }) => [
	{ type: EDIT, editViewUrl: replaceEditBookingUrl(uuid) },
	{ type: BOOKINGS_HISTORY },
	{ type: TRANSACTIONS_HISTORY },
	{ type: PRINT_BOOKING },
];
