import React from 'react';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';

// Import utils
import { useTranslations } from 'components/utilities';

// Import components
import { AdditionalOfficeFieldsRow } from '../AdditionalOfficeFieldsRow';

// Import styles
import { useStyles } from './AdditionalOffices.styles';

export const AdditionalOffices = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { mutators } = useForm();

	const handleOnAddAdditionalOffice = () =>
		mutators.push('additional_offices', undefined);

	return (
		<Box>
			<Typography className={classes.title}>
				{t('offices.form.additional_offices.title')}
			</Typography>
			<Typography className={classes.message}>
				{t('offices.form.additional_offices.add_additional_office_message')}
			</Typography>
			<FieldArray name="additional_offices">
				{({ fields }) =>
					fields.map((name, index) => (
						<Box key={name}>
							{index === 0 && <Divider className={classes.divider} />}
							<AdditionalOfficeFieldsRow index={index} fields={fields} />
							<Divider className={classes.divider} />
						</Box>
					))
				}
			</FieldArray>
			<Button
				variant="contained"
				color="primary"
				size="large"
				type="button"
				onClick={handleOnAddAdditionalOffice}
			>
				{t('common.buttons.add_additional_office')}
			</Button>
		</Box>
	);
};
