import * as Yup from 'yup';
import i18n from 'i18next';

const requiredField = i18n.t('common.fields.required_field');
const maxCharacters = i18n.t('common.fields.maximum_characters', { value: 25 });
const useCompanyBankKey = 'use_company_bank';

const detailsShape = {
	name: Yup.object().shape({
		en: Yup.string().required(requiredField).max(25, maxCharacters),
		ar: Yup.string().required(requiredField).max(25, maxCharacters),
	}),
	contact_number: Yup.string().required(requiredField),
	number_prefix: Yup.string().required(requiredField),
	person_name: Yup.string().required(requiredField),
	public_contact_number: Yup.string().required(requiredField),
	public_number_prefix: Yup.string().required(requiredField),
};

const locationShape = {
	address: Yup.string().required(requiredField),
	city: Yup.string().required(requiredField),
	country: Yup.string().required(requiredField),
	lat: Yup.number().required(requiredField),
	lng: Yup.number().required(requiredField),
};

export const validationSchema = Yup.object().shape({
	details: Yup.object().shape(detailsShape),
	location: Yup.object().shape(locationShape),
	company: Yup.object().shape({
		uuid: Yup.string().required(requiredField).nullable(),
	}),
	bank: Yup.object().shape({
		use_company_bank: Yup.boolean(),
		bank_name: Yup.string().when(useCompanyBankKey, {
			is: false,
			then: () => Yup.string().required(requiredField),
			otherwise: () => Yup.string(),
		}),
		iban_number: Yup.string().when(useCompanyBankKey, {
			is: false,
			then: () => Yup.string().required(requiredField),
			otherwise: () => Yup.string(),
		}),
		name: Yup.string().when(useCompanyBankKey, {
			is: false,
			then: () => Yup.string().required(requiredField),
			otherwise: () => Yup.string(),
		}),
		number: Yup.string().when(useCompanyBankKey, {
			is: false,
			then: () => Yup.string().required(requiredField),
			otherwise: () => Yup.string(),
		}),
	}),
	commercial_record: Yup.object().shape({
		number: Yup.number().required(requiredField),
		file_path: Yup.string().required(requiredField),
		file_name: Yup.string().required(requiredField),
	}),
	additional_offices: Yup.array()
		.nullable()
		.transform((value, originalValue) =>
			originalValue?.length === 0 ? null : value
		)
		.of(
			Yup.object().shape({
				details: Yup.object().shape(detailsShape),
				location: Yup.object().shape(locationShape),
			})
		),
	commission: Yup.object().shape({
		start_date: Yup.date().required(requiredField),
		end_date: Yup.date().required(requiredField),
		percentage: Yup.number().required(requiredField),
	}),
	supplier_notes: Yup.object().shape({
		en: Yup.string().required(requiredField),
		ar: Yup.string().required(requiredField),
	}),
});
