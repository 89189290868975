import React from 'react';
import { fetchUsers } from 'store/actions';

// Import columns
import { columns, COLUMN_ACCESSORS } from './columns';

// Import components
import { TableBodyRow, OuterToolbarElements } from './components';
import { TableRPC } from 'components/utilities';

export const Users = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMN_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchUsers}
			title="nav.administrative.users"
			outerToolbarElements={<OuterToolbarElements />}
		/>
	);
};
