import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import { string } from 'prop-types';

// Import utilities
import { useMapLocation } from 'components/utilities';

// Import components
import { AdditionalFields } from './components';
import {
	LocationAutocompleteFieldAdapter,
	GoogleMapsScriptLoader,
	Grid,
} from 'components/elements';

// Import styles
import { useGoogleMapsStyles } from 'theme/styles/googleMaps';
import { useStyles } from './Address.styles';

export const Address = React.memo(({ section }) => {
	const mapClasses = useGoogleMapsStyles();
	const classes = useStyles();

	const sectionName = section.startsWith('.')
		? section.replace('.', '')
		: section;

	const {
		t,
		fieldDisabled,
		isAirportCheckboxDisabled,
		markerLocation,
		setAutocompleteValue,
		onChange,
		onDragEnd,
	} = useMapLocation({ includeIsAirport: true, section: sectionName });

	return (
		<>
			<Grid item md={6} xs={12} className={classes.addressField}>
				<Field
					fullWidth
					component={LocationAutocompleteFieldAdapter}
					type="text"
					name={`${sectionName}.address`}
					label={t('offices.form.location.address')}
					margin="dense"
					variant="outlined"
					setAutocompleteValue={setAutocompleteValue}
					onChange={onChange}
					placeholder={t('offices.form.location.address')}
					disabled={fieldDisabled()}
					required
				/>
			</Grid>
			<AdditionalFields
				isAirportCheckboxDisabled={isAirportCheckboxDisabled}
				fieldDisabled={fieldDisabled}
				section={sectionName}
			/>
			<Grid item md={6} xs={12}>
				<GoogleMapsScriptLoader>
					<GoogleMap
						mapContainerClassName={clsx([
							mapClasses.mapContainer,
							classes.mapContainer,
						])}
						center={markerLocation}
						zoom={15}
					>
						<Marker position={markerLocation} draggable onDragEnd={onDragEnd} />
					</GoogleMap>
				</GoogleMapsScriptLoader>
			</Grid>
		</>
	);
});

Address.propTypes = {
	section: string,
};
