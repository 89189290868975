import React from 'react';
import { string } from 'prop-types';

// Import components
import { ChipLabel } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

export const ValidationChip = ({ label, color = 'success' }) => {
	const { t } = useTranslations();

	return <ChipLabel label={t(label)} color={color} />;
};

ValidationChip.propTypes = {
	label: string,
	color: string,
};
