import { pick } from 'lodash';
import { useFormState } from 'react-final-form';

// Import helpers
import { TABS_SECTIONS } from './helpers';

export const useFormContent = () => {
	const { submitErrors, errors, submitFailed } = useFormState();

	const isTabError = (index) => {
		const section = TABS_SECTIONS[index];

		return (
			Object.keys(
				pick({ ...(submitFailed && errors), ...submitErrors }, section)
			).length > 0
		);
	};

	return {
		isTabError,
	};
};
