import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
	},
	name: {
		color: theme.palette.text.primary,
		fontWeight: '500',
	},
	amount: {
		color: theme.palette.primary.main,
		fontSize: '32px',
		fontWeight: '500',
	},
	unit: {
		color: theme.palette.text.secondary,
	},
	box: {
		display: 'grid',
		width: '100%',
	},
}));
