import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import {
	BusinessHoursFieldAdapter,
	TextFieldAdapter,
} from 'components/elements';

// Import helpers
import { profileDataSelector } from 'store/selectors';
import { OFFICE_MANAGER } from 'helpers';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

export const OfficeHours = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { submitting } = useFormState();

	const { role } = useSelector(profileDataSelector);

	const isOfficeManager = OFFICE_MANAGER === role[0];

	return (
		<Grid container spacing={3}>
			<Typography className={classes.titleSmall}>
				{t('offices.form.office_hours.title')}
			</Typography>
			<Grid item md={12} xs={12}>
				<Field
					name="office_hours"
					component={(props) => (
						<BusinessHoursFieldAdapter
							isOfficeManager={isOfficeManager}
							{...props}
						/>
					)}
					disabled={submitting}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="number"
					label={t('offices.form.office_hours.min_pick_up_time')}
					name="options.min_pickup_time"
					margin="dense"
					variant="outlined"
					disabled={isOfficeManager || submitting}
					required
					inputProps={{ min: 0 }}
				/>
			</Grid>
		</Grid>
	);
};
