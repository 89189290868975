import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	fileLabel: {
		fontSize: '13px',
		color: theme.palette.gray.medium,
		fontFamily: 'Inter',
		fontWeight: 500,
		lineHeight: '16px',
		letterSpacing: '0.3px',
		marginBottom: '12px',
	},
	removeUpAndDownArrows: {
		'& input[type=number]': {
			'-moz-appearance': 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
	field: {
		'&:placeholder': {
			color: theme.palette.gray.dark,
		},
	},
	checkboxWrapper: {
		display: 'flex',
		alignItems: 'flex-end',
	},
}));

export const getAllOptionStyles = (isAllOptionChecked) => ({
	padding: '14px',
	minWidth: '250px',
	width: '100%',
	borderRadius: '8px',
	'&:hover': {
		backgroundColor: '#F3F3F3',
	},
	backgroundColor: isAllOptionChecked ? '#DCDFE3' : '#FFFFFF',
	cursor: 'pointer',
});
