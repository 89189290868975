import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import _ from 'lodash';

// Import utilities and helpers
import { useSubscriptionPrices } from 'queries';
import { useProfile } from 'components/utilities';
import {
	RATE_SECTION_TYPES,
	SUBSCRIPTION_MODEL_PERIODS,
	SUBSCRIPTION_MODEL_PERIODS_TEXT,
} from 'helpers';

const { DAILY, WEEKLY, MONTHLY, ANNUAL } = RATE_SECTION_TYPES;

export const useSubscriptionModel = ({ section }) => {
	const { submitting, values, initialValues } = useFormState();

	const { change } = useForm();

	const { isAdmin } = useProfile();

	const { is_enabled: isSubscriptionActive, prices: subscriptionPrices } =
		values?.carInfo?.subscription || {};

	const activeCheckboxName = `carInfo.${section}.is_enabled`;

	const isCardContainerShown = isAdmin || isSubscriptionActive;

	const initialDiscountPrices = {
		monthlyDiscountPrice:
			initialValues.carInfo.prices?.monthly?.discount_nett_price,
		yearlyDiscountPrice:
			initialValues.carInfo.prices?.annual?.discount_nett_price,
		isEnabled: initialValues.carInfo.subscription?.is_enabled,
	};

	const pickedDiscountPrices = {
		monthlyDiscountPrice: values.carInfo.prices?.monthly?.discount_nett_price,
		yearlyDiscountPrice: values.carInfo.prices?.annual?.discount_nett_price,
		isEnabled: values.carInfo.subscription?.is_enabled,
	};

	const calculationsChanged = !_.isEqual(
		initialDiscountPrices,
		pickedDiscountPrices
	);

	const specificDiscountPrices = _.pick(values.carInfo.prices, [
		DAILY,
		WEEKLY,
		MONTHLY,
		ANNUAL,
	]);

	const allDiscountPrices = Object.values(specificDiscountPrices);
	const allSubscriptionPrices = Object.values(subscriptionPrices);

	const isActiveCheckboxDisabled = allDiscountPrices.some(
		({ discount_nett_price }) => !+discount_nett_price
	);

	const enableCalculation = () => {
		const allPricesEqualZero = allSubscriptionPrices.every(
			({ nett_price }) => nett_price === 0
		);

		return allPricesEqualZero && !isActiveCheckboxDisabled;
	};

	const calculationEnabled = enableCalculation();

	const { data, isFetching, isError } = useSubscriptionPrices(
		pickedDiscountPrices,
		calculationEnabled
	);

	const convertAllSubscriptionPrices = () => {
		const periodOrder = Object.values(SUBSCRIPTION_MODEL_PERIODS);
		return periodOrder.reduce((acc, currPeriod) => {
			const subscriptionPrice = allSubscriptionPrices.find(
				({ period }) => period === currPeriod
			);
			return [...acc, subscriptionPrice];
		}, []);
	};

	const getCards = () => {
		const convertedSubscriptionPrices = convertAllSubscriptionPrices();
		const prices = calculationEnabled ? data : convertedSubscriptionPrices;

		return prices.map(({ nett_price, period }) => ({
			label: `cars.form.subscription_model.${period}_months_heading`,
			name: `carInfo.${section}.prices.${SUBSCRIPTION_MODEL_PERIODS_TEXT[period]}.nett_price`,
			countedValue: nett_price,
			calculationsChanged,
			disabled: !isAdmin || period === SUBSCRIPTION_MODEL_PERIODS.ONE,
			required: isSubscriptionActive,
		}));
	};

	const cards = getCards();

	const handleOnActiveCheckboxChange = (_, checked) => {
		const newState = isActiveCheckboxDisabled ? false : checked;
		change(activeCheckboxName, newState);
	};

	useEffect(() => {
		if (isActiveCheckboxDisabled) {
			change(activeCheckboxName, false);
		}
		// eslint-disable-next-line
	}, [isActiveCheckboxDisabled]);

	return {
		onActiveCheckboxChange: handleOnActiveCheckboxChange,
		activeCheckboxName,
		submitting,
		cards,
		isActiveCheckboxDisabled,
		isSubscriptionActive,
		isCardContainerShown,
		isFetching,
		isError,
		isAdmin,
	};
};
