import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	divider: {
		width: '100%',
		margin: theme.spacing(5, 0),
	},
	title: {
		fontSize: 18,
		fontWeight: 500,

		'& span': {
			color: theme.palette.text[3],
			fontWeight: 400,
			fontSize: 16,
		},
	},
	additionalOfficeInfo: {
		paddingTop: theme.spacing(3),
	},
}));
