import { useQuery } from 'react-query';

// Import utils and helpers
import services from 'services/services';
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useVehicleModelList = ({ manufacturerUuid }) => {
	const body = {
		vehicle_maker: {
			uuid: manufacturerUuid,
		},
	};

	const enabled =
		!!manufacturerUuid &&
		manufacturerUuid !== 'all' &&
		manufacturerUuid?.length;

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CARS.SUPPLIER_MODELS(JSON.stringify(body)),
		async () => await services.post(ENDPOINTS.CARS.MODELS, body),
		{ refetchOnWindowFocus: false, cacheTime: 0, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
