// Import helpers
import { FIELD_TYPES } from 'helpers';

const companiesMultiSelectLabelExtractor = (options, currentUuid) =>
	currentUuid === 'all'
		? null
		: options.find(({ uuid }) => uuid === currentUuid)?.name;

export const getMultiSelectField = ({ companiesList, values }) => ({
	type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
	name: 'company_uuids',
	label: 'common.fields.company_name',
	multiSelectLabelExtractor: companiesMultiSelectLabelExtractor,
	currentMultiSelectValues: values.company_uuids,
	options: companiesList,
	searchExtractor: 'children[1]',
	showAllOption: true,
	required: true,
	labelExtractor: ({ name }) => name,
	keyExtractor: ({ uuid }) => uuid,
});
