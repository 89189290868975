import React from 'react';
import { useHistory } from 'react-router-dom';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import helpers
import { replaceEditCorporateUrl } from 'helpers';

// Import components
import { Button } from 'components/elements';

export const ActionTableCell = ({ cell }) => {
	const { push } = useHistory();

	const url = replaceEditCorporateUrl(cell.row.original.uuid);
	const goToEditForm = () => push(url);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Button text="common.buttons.edit" onClick={goToEditForm} size="tiny" />
		</TableCell>
	);
};

ActionTableCell.propTypes = {
	cell: object,
};
