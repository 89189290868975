import { useFormState, useForm } from 'react-final-form';
import { useEffect } from 'react';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const useCommissionFields = () => {
	const { values } = useFormState();
	const { change } = useForm();
	const { commission } = values;

	const startDate = commission.start_date;
	const endDate = commission.end_date;

	const FIELDS = [
		{
			type: FIELD_TYPES.DATE,
			label: 'offices.form.commission_percentage.start_date',
			name: 'commission.start_date',
			required: true,
			minDate: false,
			md: 6,
		},
		{
			type: FIELD_TYPES.DATE,
			label: 'offices.form.commission_percentage.end_date',
			name: 'commission.end_date',
			required: true,
			disabled: !startDate,
			minDate: startDate && new Date(startDate),
			md: 6,
		},
		{
			type: FIELD_TYPES.NUMBER,
			label: 'offices.form.commission_percentage.revenue_percentage',
			name: 'commission.percentage',
			required: true,
			md: 6,
		},
	];

	useEffect(() => {
		if (!!startDate && !!endDate) {
			if (startDate > endDate) {
				change('commission.end_date', null);
			}
		}
		// eslint-disable-next-line
	}, [startDate, endDate]);

	return { FIELDS };
};
