import React from 'react';
import {
	Box,
	CircularProgress,
	Typography,
	Card,
	CardContent,
} from '@material-ui/core';

// Import components
import { Grid } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';
import { useStatistics } from './useStatistics';

// Import styles
import { useStyles } from './Statistics.styles';

export const Statistics = () => {
	const { statistics } = useStatistics();

	const { t } = useTranslations();

	const classes = useStyles();

	if (!statistics) {
		return (
			<Box className={classes.box}>
				<CircularProgress size={40} />
			</Box>
		);
	}

	return (
		<Grid container spacing={2}>
			{statistics.map(({ name, value, unit }) => (
				<Grid item key={name} xs={6} md={3}>
					<Card>
						<CardContent className={classes.container}>
							<Typography className={classes.name}>{t(name)}</Typography>
							<Typography className={classes.amount} component="span">
								{value}{' '}
								<Typography className={classes.unit} component="span">
									{t(unit)}
								</Typography>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);
};
