// Import helpers
import { FIELD_TYPES } from 'helpers';

export const CONTACTS_FIELDS = ({ section }) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'offices.form.details.contact_person_name',
		name: `${section}.details.person_name`,
		md: 6,
		required: true,
	},
	{
		type: FIELD_TYPES.PHONE,
		label: 'offices.form.details.contact_person_number',
		name: `${section}.details.contact_number`,
		prefixName: `${section}.details.number_prefix`,
		md: 6,
		required: true,
		defaultValue: '',
	},
	{
		type: FIELD_TYPES.PHONE,
		label: 'offices.form.details.office_number',
		name: `${section}.details.public_contact_number`,
		prefixName: `${section}.details.public_number_prefix`,
		md: 6,
		required: true,
		defaultValue: '',
	},
];
