import React from 'react';
import { object, string } from 'prop-types';
import {
	FormControlLabel,
	Checkbox,
	FormControl,
	Typography,
} from '@material-ui/core';

// Import components
import { InputError } from 'components/elements';

// Import styles
import { useStyles } from './styles';
import clsx from 'clsx';

const CheckboxFieldAdapter = ({
	input,
	meta,
	labelText,
	topLabel,
	...rest
}) => {
	const classes = useStyles();

	const error = !!(meta.touched && (meta.error || meta.submitError));

	return (
		<FormControl component="fieldset" error={error}>
			{topLabel && (
				<Typography className={classes.topLabel}>{topLabel}</Typography>
			)}
			<FormControlLabel
				control={<Checkbox {...input} {...rest} />}
				label={
					<Typography className={clsx(input.checked && classes.bold)}>
						{labelText}
					</Typography>
				}
			/>
			<InputError meta={meta} />
		</FormControl>
	);
};

CheckboxFieldAdapter.propTypes = {
	input: object.isRequired,
	labelText: string.isRequired,
	meta: object.isRequired,
	topLabel: string,
};

export default CheckboxFieldAdapter;
