import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import components
import { ButtonProgress } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './BottomBar.styles';

export const BottomBar = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<Grid container className={classes.container} justifyContent="flex-end">
			<Grid item>
				<ButtonProgress
					isLoading={submitting}
					disabled={submitting}
					variant="contained"
					color="primary"
					type="submit"
					size="large"
				>
					{t('common.buttons.save')}
				</ButtonProgress>
			</Grid>
		</Grid>
	);
};
