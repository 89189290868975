// Import helpers
import { FIELD_TYPES } from 'helpers';

export const HOLIDAY_CHECKBOX_FIELD = ({ name }) => ({
	type: FIELD_TYPES.CHECKBOX,
	name: 'holidays',
	label: `public_holidays.form.${name}`,
	md: 4,
	value: name,
});
