import React from 'react';
import { bool, string } from 'prop-types';
import { Divider, Grid, Typography } from '@material-ui/core';

// Import components
import {
	BaseDetails,
	Address,
	Contacts,
	DriverLicense,
	BankInfo,
	CommercialRecord,
	ServiceTypes,
	OfficeLogo,
	CommissionPercentage,
} from 'views/Offices/OfficesForm/components';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './OfficeInfo.styles';

export const OfficeInfo = ({
	isAdditionalOffice = false,
	section = '',
	className,
}) => {
	const classes = useStyles();

	const { t } = useTranslations();

	return (
		<Grid container spacing={4} className={classes[className] || ''}>
			{!isAdditionalOffice && (
				<Grid item xs={12}>
					<Typography className={classes.title}>
						{`${t('common.fields.office')} #1 `}
						<span>{`(${t('common.fields.main_office')})`}</span>
					</Typography>
				</Grid>
			)}
			<Grid item xs={12}>
				<BaseDetails
					isAdditionalOffice={isAdditionalOffice}
					section={section}
				/>
			</Grid>
			<Grid item xs={12}>
				<Address section={`${section}.location`} />
			</Grid>
			<Grid item xs={12}>
				<Contacts section={section} />
			</Grid>
			{!isAdditionalOffice && (
				<>
					<Divider className={classes.divider} />
					<Grid item xs={12}>
						<ServiceTypes />
					</Grid>
					<Divider className={classes.divider} />
					<Grid item xs={12}>
						<DriverLicense />
					</Grid>
					<Divider className={classes.divider} />
					<Grid item xs={12}>
						<BankInfo />
					</Grid>
					<Divider className={classes.divider} />
					<Grid item xs={12}>
						<OfficeLogo />
					</Grid>
					<Divider className={classes.divider} />
					<Grid item xs={12}>
						<CommissionPercentage />
					</Grid>
					<Divider className={classes.divider} />
					<Grid item xs={12}>
						<CommercialRecord />
					</Grid>
				</>
			)}
		</Grid>
	);
};

OfficeInfo.propTypes = {
	isAdditionalOffice: bool,
	section: string,
	className: string,
};
