// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FIELDS = [
	{
		type: FIELD_TYPES.IMAGE_FILE,
		name: 'logo.file_path',
		fileNameField: 'logo.file_name',
		previewFileState: 'logo',
		uploadButtonWidth: 12,
		md: 6,
		imageFileButtonSize: 'medium',
		uploadBtnFullWidth: false,
	},
];
