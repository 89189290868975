import React from 'react';
import { bool } from 'prop-types';

// Import utils and helpers
import { formatValues, initialValues } from './helpers';

// Import components
import { TableFiltersToolbar } from 'components/elements';
import { FilterFields, AdditionalFilterFields } from './components';

export const Filters = ({ isExtended }) => {
	return (
		<TableFiltersToolbar
			initialValues={initialValues}
			formatValues={formatValues}
			dialogFilters={<FilterFields isExtended={isExtended} />}
			prevAdditionalFilters={<AdditionalFilterFields />}
		/>
	);
};

Filters.propTypes = {
	isExtended: bool,
};
