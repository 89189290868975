import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 28,
		marginBottom: theme.spacing(4),
		fontWeight: 500,
	},
	titleSmall: {
		fontSize: 18,
		fontWeight: 600,
		padding: theme.spacing(2),
	},
	sectionTitle: {
		fontWeight: 600,
		fontSize: 18,
		marginBottom: theme.spacing(4),
	},
	container: {
		padding: theme.spacing(4),
		minHeight: 'calc(100vh - 170px)',
	},
	divider: {
		width: '100%',
		margin: theme.spacing(5, 0),
	},
}));
