import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { DRIVER_LICENSE_FIELDS } from './helpers';

// Import styles
import { useStyles } from '../../OfficesForm.styles';

export const DriverLicense = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	return (
		<Box>
			<Typography className={classes.sectionTitle}>
				{t('offices.form.driver_licence.title')}
			</Typography>
			<Grid container direction="column" spacing={2}>
				{DRIVER_LICENSE_FIELDS.map(renderFields)}
			</Grid>
		</Box>
	);
};
