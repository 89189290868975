import React from 'react';
import { bool } from 'prop-types';
import { Box } from '@material-ui/core';

// Import utils and helpers
import { useTranslations, useCommonFields } from 'components/utilities';
import { useFormContent } from './useFormContent';

// Import components
import { ButtonProgress } from 'components/elements';

// Import styles
import { useStyles } from './FormContent.styles.js';

export const FormContent = ({ isButtonLoading }) => {
	const { renderFields } = useCommonFields();
	const { multiSelectField } = useFormContent();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			{renderFields(multiSelectField)}
			<ButtonProgress
				isLoading={isButtonLoading}
				disabled={isButtonLoading}
				variant="contained"
				color="primary"
				size="medium"
				type="submit"
			>
				{t('common.buttons.save')}
			</ButtonProgress>
		</Box>
	);
};

FormContent.propTypes = {
	isButtonLoading: bool,
	isFetching: bool,
};
