export const COLUMN_ACCESSORS = {
	ACTIVE: 'active',
	ACTIONS: 'actions',
};

export const columns = [
	{
		Header: 'users.table.headers.name',
		accessor: 'full_name',
	},
	{
		Header: 'users.table.headers.phone_number',
		accessor: 'phone',
		disableSortBy: true,
	},
	{
		Header: 'users.table.headers.email',
		accessor: 'email',
		disableSortBy: true,
	},
	{
		Header: 'users.table.headers.role',
		accessor: 'role',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMN_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.actions',
		accessor: COLUMN_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
